import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import FooterCTA from '../components/global/CTA/FooterCTA/FooterCTA';
import TopBar from '../components/blog-page/top-bar/TopBar';
import PostHolder from '../components/blog-page/post-holder/PostHolder';
import { CONSTANTS } from '../../utils/constants';
import { Title } from '../components/blog-page/styles';

const BlogCategoryPage = ({ data }) => {
	const { nodes } = data.allContentfulBlogPost;
	const { seoImage } = data.allContentfulBlogHome.nodes[0];
	const blogCategorySeo =
		data.allContentfulBlogHome.nodes[0].blogCategorySeo.categories[0];
	const categorySEO = blogCategorySeo.filter(
		el => el.category === nodes[0].postCategory,
	)[0];
	return (
		<Layout>
			<SEO
				title={categorySEO?.data?.title}
				description={categorySEO?.data?.description}
				image={seoImage}
			/>
			<WrapperOne>
				<Title>Blog</Title>
				<TopBar content={nodes[0].category} />

				<PostHolder data={nodes} subtitle={nodes[0].category} />
			</WrapperOne>
			<FooterCTA
				title={CONSTANTS.CTA_TITLE}
				subtitle={CONSTANTS.CTA_SUB_TITLE}
				link={CONSTANTS.CTA_BUTTON_LINK}
				buttonText={CONSTANTS.CTA_BUTTON_TEXT}
				style={{ marginTop: '70px' }}
			></FooterCTA>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String) {
		allContentfulBlogPost(
			filter: { postCategory: { eq: $slug } }
			sort: { order: DESC, fields: createdAt }
		) {
			nodes {
				addToMostPopularPosts
				author
				category
				coverImage {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				featuredPost
				slug
				timeToRead
				title
				postCategory
			}
		}
		allContentfulBlogHome {
			nodes {
				blogCategorySeo {
					categories {
						data {
							description
							title
						}
						category
					}
				}
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
			}
		}
	}
`;
export default BlogCategoryPage;
